<template>
    <div class="main-indent">
        <Header/>

        <main>
            <router-view />
        </main>


        <CookiesSettings v-if="isShowCookiesPopup"
                         @hide="isShowCookiesPopup = false"
        />

        <ScrollToTop/>

        <Footer/>
    </div>
</template>

<script>
export default {
    name: "defaultLayout",

    components: {
        Header: () => import('@/components/Header.vue'),
        Footer: () => import('@/components/Footer.vue'),
        ScrollToTop: () => import('@/components/ScrollToTop.vue'),
        CookiesSettings: () => import('@/components/CookiesSettings.vue')
    },

    data() {
        return {
            isShowCookiesPopup: true
        }
    },

    mounted() {
        this.isShowCookiesPopup = !document.cookie.split("; ").some(cookie => cookie.startsWith("cookie="))
    }
}
</script>
