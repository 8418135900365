import Vue from "vue";
import Router from "vue-router";

import DefaultLayout from "@/layouts/defaultLayout.vue";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: DefaultLayout,
            children: [
                {
                    path: "",
                    name: "HomePage",
                    component: () => import('@/pages/Home.vue'),
                },
                {
                    path: "en",
                    redirect: "/"
                },
                {
                    path: "fr",
                    redirect: "/"
                },
            ],
        },
        {
            path: "/services",
            component: DefaultLayout,
            children: [
                {
                    path: "",
                    name: "ServicesPage",
                    component: () => import('@/pages/Services.vue')
                },
                {
                    path: "prestations-intellectuelles",
                    name: "ServicesIntellectual",
                    component: () => import('@/pages/ServicesType.vue'),
                    meta: {
                        isRecruitment: false
                    }
                },
                {
                    path: "recrutement",
                    name: "ServicesRecruitment",
                    component: () => import('@/pages/ServicesType.vue'),
                    meta: {
                        isRecruitment: true
                    }
                }
            ],
        },
        {
            path: "/en/our-services",
            redirect: "/services"
        },
        {
            path: "/fr/our-services",
            redirect: "/services"
        },
        {
            path: "/en/service/recruitment",
            redirect: "/services/recrutement"
        },
        {
            path: "/fr/service/recruitment",
            redirect: "/services/recrutement"
        },
        {
            path: "/contact",
            component: DefaultLayout,
            children: [
                {
                    path: "candidature/:offerName?",
                    name: "ContactCandidature",
                    component: () => import('@/pages/Contact.vue'),
                },
                {
                    path: "deposer-votre-offre",
                    name: "ContactEnterprise",
                    component: () => import('@/pages/Contact.vue'),
                    meta: {
                        isEnterprise: true
                    }
                }
            ],
        },
        {
            path: "/fr/contact-us",
            redirect: "/#contact"
        },
        {
            path: "/en/contact-us",
            redirect: "/#contact"
        },
        {
            path: "/nos-valeurs",
            component: DefaultLayout,
            children: [
                {
                    path: "",
                    name: "ValuesPage",
                    component: () => import('@/pages/Values.vue'),
                }
            ],
        },
        {
            path: "/fr/nos-valeurs",
            redirect: "/nos-valeurs"
        },
        {
            path: "/en/nos-valeurs",
            redirect: "/nos-valeurs"
        },
        {
            path: "/missions",
            component: DefaultLayout,
            children: [
                {
                    path: "",
                    name: "MissionsPage",
                    component: () => import('@/pages/Missions.vue'),
                },
                {
                    path: "/missions/:offerName?",
                    name: "MissionDetails",
                    component: () => import('@/pages/Missions.vue'),
                }
            ],
        },
        {
            path: "/offers",
            redirect: "/missions"
        },{
            path: "/fr/missions",
            redirect: "/missions"
        },
        {
            path: "/en/missions",
            redirect: "/missions"
        },
        {
            path: "/mentions-legales",
            component: DefaultLayout,
            children: [
                {
                    path: "",
                    name: "LegalNotice",
                    component: () => import('@/pages/LegalNotice.vue'),
                }
            ],
        },
        {
            path: "/politique-de-confidentialite-et-cgu",
            component: DefaultLayout,
            children: [
                {
                    path: "",
                    name: "PrivacyPolicy",
                    component: () => import('@/pages/PrivacyPolicy.vue'),
                }
            ],
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            if (to.hash === '#contact') {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        const element = document.querySelector(to.hash);

                        if (element) {
                            const top = element.getBoundingClientRect().top + window.scrollY - 150;

                            window.scrollTo({ top, behavior: 'smooth' });
                        }
                        resolve();
                    }, 300);
                });
            } else {
                return {
                    selector: to.hash,
                    behavior: 'smooth'
                };
            }
        }

        return savedPosition ? savedPosition : { x: 0, y: 0 }
    }
});
