import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import "@/assets/styles/global.scss"

import clickOutside from '@/directives/clickOutside'

Vue.directive('click-outside', clickOutside)

import VueI18n from 'vue-i18n'
import fr from "@/locales/fr"

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: { fr }
});

Vue.config.productionTip = false

new Vue({
    i18n,
    render: h => h(App),
    router,
}).$mount('#app')
